import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// 自动根据浏览器系统语言设置语言
const navLang = navigator.language.substring(0, 2)
const localLang = navLang || false
console.log(navigator.language)
console.log(navLang)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang')||localLang|| 'en', // 语言标识
  fallbackLocale: 'zh',
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    "zh": require("./zh"), // 中文语言包
    'en': require('./en'),    // 英文语言包
  }
});
export {i18n}