<template>
  <div class="faqDiv">
    <div class="pageWidth">
      <h3>FAQ {{ $t("faq[0]") }}</h3>
      <ul>
        <li>
          <em class="iconfont"></em><i class="iconfont"></i>
          <h4>{{ $t("faq[1]") }}</h4>
          <span v-html="$t('faq[2]')"></span>
        </li>
        <li>
          <em class="iconfont"></em><i class="iconfont"></i>
          <h4>{{ $t("faq[3]") }}</h4>
          <span>{{ $t("faq[4]") }}</span>
        </li>
        <li>
          <em class="iconfont"></em><i class="iconfont"></i>
          <h4>{{ $t("faq[5]") }}</h4>
          <span>{{ $t("faq[6]") }}</span>
        </li>
        <li>
          <em class="iconfont"></em><i class="iconfont"></i>
          <h4>{{ $t("faq[7]") }}</h4>
          <span>{{ $t("faq[8]") }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
}
</script>

