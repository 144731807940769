import Vue from 'vue'
import App from './App.vue'
import "./assets/index.css"
import VueClipboard from 'vue-clipboard2'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueClipboard)
Vue.config.productionTip = false
import {i18n} from './locale'
new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
