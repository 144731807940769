<template>
  <div class="aboutDiv">
          <div class="pageWidth">
            <h3>{{$t("about[0]")}}</h3>
            <ul class="icons">
              <li><i class="iconfont"></i><span>{{$t("about[1]")}}</span></li>
              <li><i class="iconfont"></i><span>{{$t("about[2]")}}</span></li>
              <li><i class="iconfont"></i><span>{{$t("about[3]")}}</span></li>
            </ul>
            <div class="introduce">{{$t("about[4]")}}</div>
            <ul class="list">
              <li>
                <i class="iconfont"></i><span>{{$t("about[5]")}}</span>
              </li>
              <li>
                <i class="iconfont"></i
                ><span>{{$t("about[6]")}}</span>
              </li>
              <li>
                <i class="iconfont"></i
                ><span>{{$t("about[7]")}}</span>
              </li>
              <li>
                <i class="iconfont"></i><span>{{$t("about[8]")}}</span>
              </li>
            </ul>
          </div>
        </div>
</template>

<script>
export default {
  name: 'about'
}
</script>

