<template>
  <div id="app">
    <div id="scrollBox" class="zh">
      <div class="home">
        <div class="firstScreen">
          <div class="headDiv sticky" :class="{ show: show }">
            <div class="pageWidth">
              <div class="logo"><img src="./assets/logo.png" /></div>
              <div class="nav">
                <span class="" @click="scrollbox('homeExchange')">{{
                  $t("tops.index[0]")
                }}</span>
                <span class="" @click="scrollbox('homeAbout')">{{
                  $t("tops.index[1]")
                }}</span>
                <span class="" @click="scrollbox('homeFaq')">{{
                  $t("tops.index[2]")
                }}</span>
                <span class="service" @click="showTele"><i class="iconfont"></i> {{ $t("tops.index[3]") }}</span>
              </div>
              <!---->
              <div class="lang" @click="lang()">
                <span class="iconfont"></span><span>{{ $t("toLan") }}</span>
              </div>
            </div>
          </div>
          <div class="isMobile mobileHeader sticky">
            <div class="logo"><img src="./assets/logo_mobile.png" /></div>
            <div class="menu" @click="menu"><i class="iconfont"></i></div>
          </div>
          <div class="mobileMenuMask" :class="{ show1: show }" @click="show = false"></div>
          <div class="headDivArea"></div>
          <div class="serviceFloat" @click="showTele">
            <i class="iconfont"></i><span>{{ $t("ser[0]") }}</span>
          </div>
          <div class="popupDiv popupDivIn" v-if="tele">
            <div class="header">
              <h3>{{ $t("ser[0]") }}</h3>
              <span class="iconfont close" @click="showTele"></span>
            </div>
            <div class="body">
              <div class="servicePopup">
                <h3>{{ $t("ser[1]") }}</h3>
                <ul class="items">
                  <li class="telegram">
                    <em class="iconfont"></em><span><a target="_blank" :href="`https://t.me/${infos.serviceTG}`">@{{
                      infos.serviceTG
                    }}</a></span><button v-clipboard:copy="infos.serviceTG" v-clipboard:success="onCopy"
                      v-clipboard:error="onError">
                      <i class="iconfont"></i>{{ $t("ser[2]") }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="buttons" style="justify-content: center"><!----></div>
          </div>
          <div class="popupMask popupMaskIn" v-if="tele">1</div>
          <a id="homeExchange"></a>
          <div class="mainTitle">
            <h3>{{ $t("tops.title[0]") }}</h3>
            <!-- <h4>{{ $t("tops.title[1]") }}</h4> -->
          </div>
          <!-- <div class="sliderDiv pageWidth">
            <div class="slider">
              <ul style="margin-left: -761px">
                <div class="zhSlider">
                  <li>
                    <h3>价格调整公告：</h3>
                    <span>TrxLand
                      优惠运营时间将于5月1日结束，届时我们将对价格进行调整。请即时关注最新价格。</span>
                  </li>
                  <li>
                    <h3>温馨提示：</h3>
                    <span>本平台24小时为您提供优质服务，有任何问题可随时与在线客服沟通。</span>
                  </li>
                  <li>
                    <h3>价格调整公告：</h3>
                    <span>TrxLand
                      优惠运营时间将于5月1日结束，届时我们将对价格进行调整。请即时关注最新价格。</span>
                  </li>
                  <li>
                    <h3>温馨提示：</h3>
                    <span>本平台24小时为您提供优质服务，有任何问题可随时与在线客服沟通。</span>
                  </li>
                </div>
                <div class="enSlider">
                  <li>
                    <h3>Notice:</h3>
                    <span>We will adjust the price on May 1st, please keep an eye
                      out.</span>
                  </li>
                  <li>
                    <h3>reminder:</h3>
                    <span>We provide 24-hour service and can communicate with
                      customer service if you have any questions.</span>
                  </li>
                  <li>
                    <h3>Notice:</h3>
                    <span>We will adjust the price on May 1st, please keep an eye
                      out.</span>
                  </li>
                  <li>
                    <h3>reminder:</h3>
                    <span>We provide 24-hour service and can communicate with
                      customer service if you have any questions.</span>
                  </li>
                </div>
              </ul>
            </div>
          </div> -->

          <div class="batchExplain pageWidth" :class="{ expands1: expands }">
            <h5 @click="expan">{{ $t("batchExplain[0]") }} <i class="down-arrow" :class="{ active: expands }"></i></h5>
            <ul>
              <li>{{ $t("batchExplain[1]") }}</li>
              <li>{{ $t("batchExplain[2]") }}</li>
              <li>{{ $t("batchExplain[3]") }}</li>
              <li>{{ $t("batchExplain[4]") }}</li>
            </ul>
          </div>
          <div class="batchExchangeDiv pageWidth">
            <div class="balanceInfo isPc">
              <span>{{ $t("info[0]") }}！{{ $t("info[1]")
              }}<i>{{ this.infos.clientBalance | formatNumber }}</i>，{{ $t("info[2]")
}}<i>{{ this.infos.energyBalance | formatNumber }}</i></span>
            </div>
            <div class="balanceInfo isMobile">
              <span>
                <li>
                  {{ $t("info[1]") }}<i>{{ this.infos.clientBalance | formatNumber }}</i>
                </li>
                <li>
                  {{ $t("info[2]") }}<i>{{ this.infos.energyBalance | formatNumber }}</i>
                </li>
              </span>
            </div>
            <div class="form">
              <div class="item">
                <h5><i>01</i>{{ $t("ex.a[0]") }}</h5>
                <ul>
                  <li>
                    <strong>{{ $t("ex.a[1]") }}
                      <em v-html="$t('ex.a[2]', { energy: formatNumber(currentPack.energy) })"></em>
                    </strong>
                    <div class="dayInput">
                      <input type="number" onkeypress="return event.charCode >= 48" min="1"
                        oninput="value=value.replace(/[^\d]/g,'')" placeholder="0" v-model="transfers" /><span>{{
                          $t("ex.a[3]") }} × </span>
                      <div class="timeSelect">
                        <ul>
                          <li v-for="item in infos.packages" :key="item.Duration" @click="setPackage(item)" :class="{
                            cur: item.duration == currentPack.duration,
                          }" v-html="$t('ex.a[4]', {energyTrx: item.energyTrx,duration: formatTime(item.duration)})">
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <strong>{{ $t("ex.b[0]") }}</strong>
                    <div class="input">
                      <input type="text" :placeholder="$t('ex.b[1]')" v-model="outAddress" autocomplete="off" />
                    </div>
                  </li>
                  <li>
                    <!-- <strong>接收能量地址（每行一个，当前 <b>{{ receivAddressrNum }}</b> 个）</strong> -->
                    <strong v-html="$t('ex.b[2]', { receivAddressrNum: receivAddressrNum })
                      "></strong>
                    <div class="input addressList">
                      <textarea :placeholder="$t('ex.b[3]')" v-model="receivAddressrStr"></textarea>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="item mondyDiv">
                <h5><i>02</i>{{ $t("ex.c[0]") }}</h5>
                <ul>
                  <li>
                    <strong>{{ $t("ex.c[1]") }}</strong>
                    <div class="disabled">
                      1 {{ formatTime(this.currentPack.Duration) }} /
                      <i>{{ totalEnergyPerAccount | formatNumber }}</i> {{ $t("ex.c[2]") }}
                    </div>
                  </li>
                  <li>
                    <strong>{{ $t("ex.c[3]") }}</strong>
                    <div class="energyTotal" v-html="$t('ex.c[4]', {
                      totalEnergyPerAccount: formatNumber(totalEnergyPerAccount),
                      receivAddressrNum: receivAddressrNum,
                      totalEnergy: formatNumber(totalEnergy),
                    })
                      "></div>
                  </li>
                  <li>
                    <strong class="isPc">{{ $t("ex.d[0]") }}</strong><strong class="isMobile">{{ $t("ex.d[0]") }}</strong>
                    <div class="currency">
                      <span :class="{ cur: totalPayMethod == 'TRX' }" @click="setPayMethod('TRX')">TRX</span><span
                        :class="{ cur: totalPayMethod == 'USDT' }" @click="setPayMethod('USDT')">USDT</span>
                    </div>
                    <div class="money">
                      <span>{{ totalPayAmount | formatNumber }}
                        <em>{{ totalPayMethod }}</em></span>
                      <i class="iconfont" v-clipboard:copy="totalPayAmount" v-clipboard:success="onCopy"
                        v-clipboard:error="onError"></i>
                      <!-- <span class="hui">{{ $t("ex.d[1]") }}</span> -->
                    </div>
                    <span class="info saved" v-html="$t('ex.d[2]', {
                      totalSave: formatNumber(totalSave),
                      totalPayMethod: totalPayMethod,
                    })
                      "></span>
                  </li>
                  <li>
                    <strong>{{ $t("ex.d[3]") }}
                      <i class="iconfont qr" @click="QRcode = true"></i></strong>
                    <div class="disabled address">
                      <span>{{ currentPack.address }}</span><i class="iconfont copyBut"
                        v-clipboard:copy="currentPack.address" v-clipboard:success="onCopy"
                        v-clipboard:error="onError"></i>
                    </div>
                  </li>
                  <li><input type="password" autocomplete="off" class="apiKey" placeholder="API Key" v-model="apiKey" />
                  </li>
                  <li><button class="submit" @click="placeOrder()">
                      {{ $t("ex.d[4]") }}
                    </button></li>
                </ul>

              </div>
            </div>
            <!----><!----><!----><!---->
          </div>
        </div>
        <a id="homeAbout"></a>
        <about />
        <a id="homeFaq"></a>
        <FAQ />
        <div class="footDiv">TrxLand {{ $t("info[3]") }}</div>
        <div class="toTop toTopIn" @click="scrollbox('homeExchange')">
          <i class="iconfont" title=""></i>
        </div>
      </div>
    </div>
    <div v-if="copy">
      <div class="msgDiv" style="animation: msgDivAnimation 2s forwards">
        {{ tipsText }}
      </div>
    </div>
    <div class="popupDiv popupDivIn" v-if="submitSucess">
      <div class="header">
        <h3>{{ $t("suc[0]") }}</h3>
        <span class="iconfont close" @click="submitSucess = false"></span>
      </div>
      <div class="body">
        <div class="batchSubmitPopup">
          <h3 v-html="$t('suc[1]')"></h3>
          <ul>
            <li class="money">
              <strong>{{ $t("suc[2]") }}</strong><span>{{ this.totalPayAmount
              }}<em>{{ this.totalPayMethod }}</em></span>
            </li>
            <li>
              <qrcode :value="currentPack.address" :options="{ width: 200 }"></qrcode>
            </li>
            <li class="address">
              <strong>{{ $t("suc[3]") }}</strong><span>{{ this.currentPack.address }}</span><i class="iconfont"
                v-clipboard:copy="currentPack.address" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="buttons" style="justify-content: center">
        <button @click="submitSucess = false">{{ $t("suc[4]") }}</button><!---->
      </div>
    </div>
    <div class="popupMask popupMaskIn" v-if="submitSucess">1</div>
    <div class="popupDiv popupDivIn" v-if="QRcode">
      <div class="header">
        <h3>{{ $t("QR[0]") }}</h3>
        <span class="iconfont close" @click="QRcode = false"></span>
      </div>
      <div class="body">
        <qrcode :value="currentPack.address" :options="{ width: 200 }"></qrcode>
      </div>
      <div class="buttons" style="justify-content: center">
        <button @click="QRcode = false">{{ $t("suc[4]") }}</button><!---->
      </div>
    </div>
    <div class="popupMask popupMaskIn" v-if="QRcode">1</div>
  </div>
</template>

<script>
import about from "./components/about.vue";
import FAQ from "./components/FAQ.vue";
export default {
  name: "App",
  data() {
    return {
      show: false,
      tele: false,
      infos: { originalPrice: 0, clientBalance: 0 },
      currentPack: {
        address: "0",
        duration: 0,
        energy: 0,
        energyTrx: 0
      },
      transfers: 1,
      outAddress: "",
      receivAddressrStr: "",
      totalPayMethod: "TRX",
      copy: false,
      tipsText: "",
      apiKey: "",
      submitSucess: false,
      QRcode: false,
      expands: true,
    };
  },
  components: {
    about,
    FAQ,
  },
  filters: {
    formatNumber: function (value) {
      return Number(value).toLocaleString()
    }
  },
  computed: {
    receivAddressrNum() {
      return this.getNumberOfLines(this.receivAddressrStr);
    },
    totalEnergyPerAccount() {
      return this.transfers * this.currentPack.energy;
    },
    totalEnergy() {
      return this.receivAddressrNum * this.totalEnergyPerAccount;
    },
    totalPayAmount() {
      let e;
      if (this.totalPayMethod == "TRX") {
        e =
          this.transfers * this.currentPack.energyTrx * this.receivAddressrNum;
      } else if (this.totalPayMethod == "USDT") {
        e =
          this.transfers *
          this.currentPack.energyTrx *
          this.receivAddressrNum *
          this.infos.trxPrice;
        var t = e.toString().split(".");
        t.length > 1 &&
          (e = parseFloat(t[0] + "." + t[1].substring(0, 2)) + 0.01);
      }
      return JSON.stringify(this.unifyNumber(e, 2) * 1);
    },
    totalSave() {
      let e;
      if (this.totalPayMethod == "TRX") {
        e =
          this.transfers * this.infos.originalPrice * this.receivAddressrNum -
          this.totalPayAmount;
        // console.log(e);
      } else if (this.totalPayMethod == "USDT") {
        e =
          this.transfers *
          this.infos.originalPrice *
          this.receivAddressrNum *
          this.infos.trxPrice -
          this.totalPayAmount;
        console.log(e);
        var t = e.toString().split(".");
        t.length > 1 &&
          (e = parseFloat(t[0] + "." + t[1].substring(0, 2)) + 0.01);
      }
      return this.unifyNumber(e, 2) * 1;
    },
  },
  methods: {
    expan() {
      this.expands = this.expands ? false : true
      window.localStorage['expands'] = this.expands
    },
    formatNumber: function (value) {
      return Number(value).toLocaleString()
    },
    menu() {
      console.log(123);
      this.show = true;
    },
    onCopy() {
      this.tipsText = this.$t("cs");
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
        this.tipsText = "";
      }, 1000);
    },
    toast(e) {
      this.tipsText = e;
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
        this.tipsText = "";
      }, 2000);
    },
    onError() { },
    scrollbox(e) {
      let t = document.getElementById(e).offsetTop;
      t = this.isMobile
        ? t - document.querySelector(".mobileHeader").offsetHeight
        : t - document.querySelector(".headDiv").offsetHeight;
      document.getElementById("scrollBox").scrollTo({
        top: t,
        behavior: "smooth",
      });
      this.show = false;
    },
    setPayMethod(e) {
      this.totalPayMethod = e;
    },
    unifyNumber(e, t) {
      return e.toFixed(t) * 1;
    },
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    showTele() {
      if (this.tele) {
        this.tele = false;
        this.show = false;
      } else {
        this.tele = true;
        this.show = false;
      }
    },
    GetUrlParam(paraName) {
      var url = document.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getinfo() {
      let Code = this.GetUrlParam("code");
      // console.log(Code);
      this.axios
        .get("/api/v1/orders/query?code=" + Code)
        .then((response) => {
          // console.log(response.data);
          if (response.data.code == -9999) {
            return
          }
          this.infos = response.data.data;
          if (this.infos.packages.length > 0) {
            this.currentPack = this.infos.packages[0]
          }
        });
    },
    formatTime(e) {
      const t = Math.floor(e / 86400);
      if (t > 0)
        return this.$t("time_day", {
          num: t,
        });
      {
        const n = Math.floor(e / 3600);
        return this.$t("time_hour", {
          num: n,
        });
      }
    },
    setPackage(item) {
      this.currentPack = item;
    },
    getNumberOfLines(e) {
      (e = e.replace(/\r\n/g, ``)), (e = e.replace(/\r/g, ``));
      let t = e.split(`\n`);
      return (t = t.filter((n) => n.trim() !== "")), t.length;
    },
    lang() {
      console.log(this.$i18n.locale);
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "zh";
      } else {
        this.$i18n.locale = "en";
      }
      localStorage.setItem("lang", this.$i18n.locale);
    },
    placeOrder() {
      if (this.outAddress == "") {
        this.toast(this.$t("tos[0]"));
        return;
      }
      if (this.receivAddressrNum < 1) {
        this.toast(this.$t("tos[1]"));
        return;
      }
      if (this.apiKey == "") {
        this.toast(this.$t("tos[2]"));
        return;
      }
      this.axios
        .post("/api/v1/orders", {
          code: this.GetUrlParam("code"),
          coin_amount: this.totalPayAmount,
          coin_type: this.totalPayMethod,
          count: this.transfers,
          duration: this.currentPack.duration,
          energy_each: this.totalEnergyPerAccount,
          energy_total: this.totalEnergy,
          pay_address: this.outAddress,
          pool_address: this.currentPack.address,
          receive_addresses: this.receivAddressrStr,
          secret_key: this.apiKey,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code == -9999) {
            this.toast(response.data.message);
          } else {
            this.submitSucess = true
          }
        });
    },
  },
  mounted() {
    this.getinfo();
    console.log(!!window.localStorage.expands)
    if (window.localStorage.expands == 'false' || null) {
      this.expands = false
    } else {
      this.expands = true
    }
  },
};
</script>

<style">
</style>
