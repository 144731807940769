const content = {
  appName: "TrxLand",
  toLan: "English",
  tops: {
    index: ["兑换能量", "平台介绍", "常见问题", "在线客服"],
    title: ["大客户专属批量兑换通道", "简单方便 帮你省钱"],
  },
  time_day: "天",
  time_hour: "小时",
  cs:"复制成功",
  tos:[
    "转出地址不能为空",
    "请填写最少一个接收地址",
    "请输入apiKey",
  ],
  info: ["欢迎使用TrxLand能量租赁", "您当前可用余额:", "系统剩余能量：","便捷省钱服务平台"],
  ex: {
    a: [
      "填写兑换需求",
      "单个账户转账笔数,",
      "（每笔 = <b>{energy}</b> 能量）",
      "笔",
      "{energyTrx} TRX / 1{duration}",
    ],
    b: [
      "转出地址",
      "转出地址",
      "接收能量地址（每行一个，当前 <b>{receivAddressrNum}</b>个)",
      "请填写接收地址，每行一个"
    ],
    c: [
      "确认订单信息",
      "单个账户兑换能量总计",
      "能量",
      "兑换能量总计",
      "<em class='isPc'>每个账户</em> <i>{totalEnergyPerAccount}</i> × <i>{receivAddressrNum}</i> 账户 = <span>{totalEnergy}</span> 能量",
    ],
    d: [
      "需转账金额",
      "限时特惠",
      "较使用{totalPayMethod}转账，节省约 <i>{totalSave} {totalPayMethod}</i>",
      "请支付到以下地址，能量将在确认收款后自动到账：",
      "先点我提交订单，然后转账",
    ],
  },
  batchExplain: [
    "操作说明",
    "如果您没有API key，将无法获得地址。请联系客服索取。",
    "请在上方的表单中填写您每日需转账的次数和相关信息。系统将估算出每日需消耗的能量。",
    "确认订单信息后，点击提交按钮。待订单提交成功后，将对应的金额转入到平台账户。",
    "转账完成后，平台将根据您兑换的天数自动将对应数量的能量转入您填写的接收地址中。",
  ],
  about:[
    'TrxLand 平台介绍',
    "经济",
    "安全",
    "高效",
    "TrxLand 平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务。",
    "解决 TRX 手续费过高问题",
    "快捷方便，只需转账即可兑换能量",
    "适用USDT、合约转账、部署合约等",
    "Tron 波场用户必备省钱专家"
  ],
  faq:[
    "常见问题",
    "如何购买次数",
    "请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX/USDT即可。",
    "支付成功后，能量什么时候到账？",
    "付款完成后，正常情况下能量会在6秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。",
    "同一接收地址同时可以几笔订单？",
    "同一地址也可以多笔购买，不限制使用次数。",
    "订单可以撤回吗？",
    "当您向平台账户支付费用后，订单不支持撤回。",
  ],
  ser:[
    "在线客服",
    "有碰到任何问题，欢迎咨询在线客服",
    "点击复制",
  ],
  suc:[
    "系统提示",
    "<span>订单提交成功，</span>请在<i>30</i>分钟内完成转账。",
    "转账金额：",
    "平台收款地址：",
    "我知道了",
  ],
  QR:[
    "平台收款二维码"
  ]
};
module.exports = content;
