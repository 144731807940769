const content = {
  appName: "TrxLand",
  toLan: "中文",
  tops: {
    index: ["Buy Energy", "About", "FAQ", "Support"],
    title: ["The Easiest Way To Save Gas Fees on Tron"],
  },
  time_day: "Day",
  time_hour: "Hour",
  cs: "Copied Successfully",
  tos: [
    "The Sender address cannot be blank",
    "Please provide at least one Energy Receiving addres",
    "Please provide your API Key",
  ],
  info: [
    "Welcome to TrxLand",
    "Your Deposit Balance:",
    "TrxLand Total Energy Balance:",
    "Buy Energy on TrxLand",
  ],
  ex: {
    a: [
      "How much Energy would you like to buy?",
      "How many transactions will you make within the next day or hour?",
      "（<b>{energy}</b> energy per transaction）",
      "",
      "1 {duration} (costs {energyTrx} TRX)",
    ],
    b: [
      "Sender Address",
      "Please input the wallet address you use to deposit USDT or TRX into TrxLand",
      "Energy Receiving Address<br /> (one address per line only. Currently <b>{receivAddressrNum}</b>)",
      "Please input the wallet address to receive the purchased Energy. You may provide more than one wallet address, but one address per line only",
    ],
    c: [
      "Please check if your Buy Energy Order below is correct",
      "Total energy to be received in each Energy Receiving Address",
      "Energy",
      "Total Energy Purchased",
      "<span>{totalEnergy}</span> energy for <i>{receivAddressrNum}</i> account(s) at <i>{totalEnergyPerAccount}</i> per account",
    ],
    d: [
      "Your Payment Amount",
      "",
      "You save about <i>{totalSave} {totalPayMethod}</i>",
      "Please make your payment to the following TrxLand address. Your Energy will be transferred to your Energy Receiving Address automatically upon confirmation of receipt.",
      "Submit Order",
    ],
  },
  batchExplain: [
    "Trxland Instructions",
    "Please note that you will not be able to Buy Energy on TrxLand unless you have an API Key. Please kindly contact Support to request for an API Key.",
    "To buy Energy, please complete the form below with your estimated number of transactions that you will make within the next day or hour. TrxLand will estimate the amount of Energy that will be required for each transaction. Energy has a lifespan of either 1 day or 1 hour. Therefore, please ensure that you buy the Energy with the correct lifespan depending on whether you will use it within the next 1 day or 1 hour. If you do not use the Energy within the specified time period, it will expire.",
    "After you confirm that your Buy Energy order is correct, please click the “Submit Order” button. Once your order is successfully submitted, please transfer your payment to the designated TrxLand wallet address",
    "After you have successfully transferred your payment, TrxLand will automatically transfer your Energy to your Energy Receiving Address.",
  ],

  about: [
    "About TrxLand",
    "Saves Significant Costs",
    "Transparent & Secured",
    "Fast Execution & Settlement",
    "TrxLand offers an easy and seamless experience for you to buy Energy which is used to offset against your gas fees paid for all transactions on Tron. TrxLand helps you save significant cost especially if you are making a high volume of transactions on Tron daily. Execution and settlement on TrxLand is automated 24 hours a day to ensure we meet your needs swiftly and securely.",
    "Saves you paying excessive TRX gas fees",
    "Guaranteed fast execution and settlement 24 hours a day",
    "Supports USDT, contract transfers, deployment and more",
    "Transparent and secured for your assurance",
  ],

  faq: [
    "",
    "How to purchase Energy?",
"Step 1: Please input your estimated number of transactions that you will make within the next day or hour. If you plan to make transactions and therefore use the Energy within the next day, select the Day option. If you plan to make transactions and use the Energy within the next hour, select the Hour option. If you do not use the Energy within the specified time period, it will expire. <br/>Step 2: Please input your Sender Address, which is the wallet address you use to transfer your TRX or USDT into Trxland to purchase Energy.<br/>Step 3: Please input your Energy Receiving Address which is the wallet address you want to receive the Energy in. You may provide more than one Energy Receiving Address by entering one address on each line. If you provide more than one Energy Receiving Address, please note that the amount of Energy you buy will be applied to each and every address as if you are purchasing the same amount of Energy for multiple wallets.  <br/>Step 4: Please check and confirm that your Buy Energy Order information is correct. If everything is on order, please click the “Submit Order” button. Once the order is successfully submitted, please transfer your payment to the designated TrxLand wallet address.<br/>Step 5: After you have successfully transferred your payment, TrxLand will automatically transfer your Energy to your Energy Receiving Address(es).",
"When will the energy arrive?",
    "After you have completed payment, you will receive the Energy in your Energy Receiving Address within 6 seconds. If the network is congested, there may be a delay of between 30 to 60 seconds.",
    "How many orders can I have at the same time?",
    "Multiple purchases can be made under the same address without limits.",
    "Can I withdraw my order?",
    "Any order once submitted cannot be withdrawn",
  ],
  ser: [
    "Support",
    "If you have any questions, please feel free to reach out to our customer care consultant.",
    "Copy",
  ],
  suc: [
    "",
    "<span>Your order has been submitted successfully</span> <br/>Please kindly transfer your payment within <i>30</i> minutes.",
    "Your Payment Amount:",
    "TrxLand payment address：",
    "OK",
  ],
  QR: ["TrxLand payment QR code"],
};
module.exports = content;
